import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../about/scss/stylish-portfolio.css';
import '../cyprus/index.css';
import { withPrefix } from 'gatsby';

import { withFirebase } from '../components/Firebase/FirebaseContext';
import { withTrainings } from '../BuisenessLogic/TrainingsContext';

import Layout from '../components/layout';
import Header from '../cyprus/header';
import Services from '../cyprus/services';

import { Background } from './trainings';
import Card from '../components/Card/UserCard';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { FaPhone, FaFacebook } from 'react-icons/fa/index';

const outOfGrid = {
  width: '100%',
  'text-align': 'center',
  color: 'white',
  fontSize: '3rem',
  marginBottom: '3rem'
};

class Athletes extends Component {
  constructor(props) {
    super(props);
    this.props.firebase.base.listenToCollection('users', {
      context: this,
      state: 'users',
      then() {
        this.props.firebase.getUsers().then(users => {
          if (this.mount) this.setState({ users });
        });
      }
    });
  }
  handleClose = () => {
    this.setState({ open: false });
  };

  state = {
    users: [],
    open: false
  };

  componentDidMount() {
    this.mount = true;
    if (this.props.firebase) {
      this.props.firebase.getUsers().then(users => this.setState({ users }));
    }
  }
  componentWillUnmount() {
    this.mount = false;
  }

  render() {
    const currentUser = this.props.firebase?.auth?.currentUser?.uid;
    if (!this.state.users.length) {
      return null;
    }
    const userRegistered = this.state.users
      .filter(user => user.cyprus20)
      .some(u => u.id === currentUser);

    const portfolioItems = [
      {
        imgSrc: withPrefix('cyprus5.jpg'),
        heading: '300 метрів від моря'
      },
      {
        imgSrc: withPrefix('cyprus4.jpg'),
        heading: 'Хороша компанія'
      },
      {
        imgSrc: withPrefix('13.jpg'),
        heading: 'Комфортні кімнати'
      },
      {
        imgSrc: withPrefix('cyprus14.jpg'),
        heading: 'Простора вітальня'
      },
      {
        imgSrc: withPrefix('cyprus12.jpg'),
        heading: 'Чудовий асфальт'
      },
      {
        imgSrc: withPrefix('cyprus13.jpg'),
        heading: 'Приголомшливі краєвиди'
      }
    ];

    return (
      <div style={{ color: 'white' }}>
        <Header currentUser={currentUser} />
        <Services />
        <div className="container">
          <div className="content-section">
            <div className="row">
              <h1 className="col-md-12 text-center" style={outOfGrid}>
                Бюджет
              </h1>
            </div>
            <div className="row ">
              <div className="col-md-6" style={{ color: 'white' }}>
                <h3 style={{ color: 'white' }}>У вартість 450 € входить:</h3>

                <ul className="color-list">
                  <li>проживання</li>
                  <li>трансфер</li>
                  <li>тренування з Тренером</li>
                  <li>лекції</li>
                  <li>допомога в обслуговуванні велосипедів</li>
                  <li>тренувальний план</li>
                  <li>машина супроводу</li>
                  <li>механік на кожному тренуванні (запасні колеса, вода, їжа)</li>
                </ul>
              </div>
              <div className="col-md-6">
                <h3 style={{ color: 'white' }}>У вартість не входить:</h3>
                <ul className="color-list">
                  <li>переліт</li>
                  <li>страховка</li>
                  <li>харчування</li>
                  <li>розваги</li>
                </ul>
              </div>
              <div className="row" hidden>
                <div className="col-lg-12">
                  <div style={{ padding: 5, display: 'block' }}>
                    * Вартість може змінюватися в залежності від термінів покупки авіаквитків.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="photo" style={{ 'background-image': `url('${withPrefix('cyprus6.JPG')}')` }}>
            <h1 style={{ fontSize: '30px' }}>Увага! Рівень учасників зборів — середній</h1>
          </div>
        </div>

        <section className="content-section" id="portfolio">
          <h1 style={outOfGrid}>Вілла</h1>
          <div className="container">
            <div className="row no-gutters">
              {portfolioItems.map((project, index) => (
                <div className="col-lg-6" key={`portfolio_item_${index}`}>
                  <a
                    className="portfolio-item"
                    href="https://www.booking.com/hotel/cy/katikies-22.uk.html"
                  >
                    <span className="caption">
                      <span className="caption-content">
                        <h2>{project.heading}</h2>
                        <p className="mb-0">{project.description}</p>
                      </span>
                    </span>
                    <img className="img-fluid" src={project.imgSrc} alt="" />
                  </a>
                </div>
              ))}
            </div>
          </div>
        </section>

        <div className="container content-section">
          <Background>
            <h1 id={'team-title'} style={outOfGrid}>
              Команда
            </h1>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {this.state.users
                .filter(user => user.cyprus20)
                .map(user => (
                  <Card
                    {...user}
                    currentUser={currentUser}
                    setUserMoney={this.props.trainings.setUserMoney}
                    addUserMoney={this.props.trainings.addUserMoney}
                    key={user.id}
                  />
                ))}
            </div>

            <div style={{ ...outOfGrid, margin: '20px 0' }} id="team">
              {currentUser ? (
                !userRegistered ? (
                  <a
                    id="go"
                    className="btn btn-primary btn-xl js-scroll-trigger"
                    onClick={() => this.props.trainings.goToCyprus(currentUser)}
                  >
                    Їду з вами
                  </a>
                ) : (
                  <a
                    id="go"
                    className="btn btn-primary btn-xl js-scroll-trigger"
                    onClick={() => this.props.trainings.cancelCyprus(currentUser)}
                  >
                    Скасування
                  </a>
                )
              ) : (
                <a
                  id="go"
                  className="btn btn-primary btn-xl js-scroll-trigger"
                  onClick={() => this.setState({ open: true })}
                >
                  Їду з вами
                </a>
              )}
            </div>
          </Background>
        </div>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Контакти для запису:</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div>Дмитро Воловод</div>
              <FaPhone />
              <a style={{ color: 'black' }} href="tel:+380509276677">
                {' '}
                0509276677
              </a>
              <br />
              <FaFacebook />
              <a style={{ color: 'black' }} href="https://www.facebook.com/volovodroadtraining/">
                {' '}
                Facebook
              </a>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Ок
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const LandingPageWithFirebase = withFirebase(withTrainings(Athletes));

export default () => (
  <Layout>
    <LandingPageWithFirebase />
  </Layout>
);
