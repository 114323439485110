import React from 'react';
import { FaTshirt, FaBicycle, FaMap, FaQuestion } from 'react-icons/fa/index';
const style = {
  marginTop: '-10px',
  marginRight: '1px',
  fontSize: '43px'
};
const ServicesOffered = [
  {
    title: 'Де?',
    description: (
      <ul style={{ textAlign: 'left' }}>
        <li>Кіпр, Піссурі</li>
        <li>Гори й рівнина</li>
        <li>На ідеальному покритті</li>
      </ul>
    ),
    icon: <FaMap style={style} />
  },
  {
    title: 'Як?',
    description: (
      <ul style={{ textAlign: 'left' }}>
        <li>Посилена тренувальна програма</li>
        <li>Індивідуальний підхід до кожного</li>
        <li>Щодня на велосипеді</li>
        <li>
          <strong style={{ color: 'white', textTransform: 'underline' }}>
            Машина супроводу з механіком на кожному тренуванні
          </strong>
        </li>
        <li>Різноманітні роботи</li>
        <li>500 км за тиждень</li>
        <li>Підйоми, відпрацювання спусків, командні роботи</li>
      </ul>
    ),
    icon: <FaBicycle style={style} />
  },
  {
    title: 'Навіщо?',
    description: (
      <ul style={{ textAlign: 'left' }}>
        <li>Сезон зовсім близько</li>
        <li>Зміна обстановки у чудовій компанії</li>
        <li>Поліпшити фізичну форму</li>
        <li>Поповнити багаж знань</li>
      </ul>
    ),
    icon: <FaQuestion style={style} />
  },
  {
    title: 'З ким?',
    description: (
      <span>
        <a
          style={{ color: 'white', textTransform: 'underline' }}
          href="https://www.facebook.com/volovodroadtraining/"
        >
          Дмитро Воловод
        </a>{' '}
        ,{' '}
        <a
          style={{ color: 'white', textTransform: 'underline' }}
          href="https://www.facebook.com/volodymyr.samoilenko.75"
        >
          Механік - Самойленко Володимир
        </a>{' '}
        и{' '}
        <a style={{ color: 'white', textTransform: 'underline' }} href="#team">
          команда
        </a>
      </span>
    ),
    icon: <FaTshirt style={style} />
  }
];
const ServicesSections = () => (
  <section id="services" className="content-section bg-primary-custom text-white text-center">
    <div className="container">
      <div className="content-section-heading">
        <h3 style={{ color: '#c61309' }} className="mb-0">
          Хочеш бути сильним — тренуйся з сильними
        </h3>
        <h2 style={{ color: 'white' }} className="mb-5">
          Програма
        </h2>
      </div>
      <div className="row">
        {ServicesOffered.map((service, index) => (
          <div className="col-lg-3 col-md-6 mb-5 mb-lg-0" key={`service_${index}`}>
            <span className="service-icon rounded-circle mx-auto mb-3" style={{ color: 'black' }}>
              {service.icon}
            </span>
            <h4 style={{ color: 'white' }}>
              <strong>{service.title}</strong>
            </h4>
            <p className="text-faded mb-0">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  </section>
);
export default ServicesSections;
