import React from 'react';
import { withPrefix } from 'gatsby';

const Header = ({ currentUser }) => (
  <header
    className="masthead d-flex"
    style={{
      'background-position-y': '50%',
      backgroundImage: `url(${withPrefix('cyprus13.jpg')})`,
      maxHeight: 300
    }}
  >
    <div className="container text-center my-auto" style={{ zIndex: 999 }}>
      <h1 style={{ color: 'white' }}>Кипр</h1>
      <h3 style={{ color: 'white' }}>20-27 февраля 2020</h3>
      {!currentUser && (
        <a
          className="btn btn-primary btn-xl js-scroll-trigger"
          onClick={() => {
            document.getElementById('go').click();
          }}
        >
          Подать заявку
        </a>
      )}
    </div>
    <div
      className="overlay"
      style={{
        background: 'black',
        width: '100%',
        position: 'absolute',
        top: 0,
        opacity: 0.15,
        height: '100%'
      }}
    />
  </header>
);
export default Header;
